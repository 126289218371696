<template>
  <section class="report-wrapper">
    <b-card no-body>
      <b-card-body class="report_header pb-0">
        <div class="company">
          <h3 class="mb-1 text-left colorBlack">
            {{ userData.company_name_cn }}
          </h3>
        </div>
        <div class="title d-flex justify-content-between">
          <h5 class="mb-0 colorBlack">期數過期報表</h5>
          <label>列印時間：{{ getDateTime() }}</label>
        </div>
        <div class="info mt-50">
          <b-row>
            <b-col cols="10">
              <b-row>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="4"><b>過期日：</b></b-col>
                    <b-col
                      cols="8"
                      v-if="
                        controlValue.pay_date_from || controlValue.pay_date_to
                      "
                    >
                      {{ controlValue.pay_date_from }} -
                      {{ controlValue.pay_date_to }}
                    </b-col>
                    <b-col cols="8" v-else> 不限 </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="4"><b>貸款類別：</b></b-col>
                    <b-col cols="8" v-if="controlValue.loan_type_id">{{
                      loanTypeOptions &&
                      loanTypeOptions.length > 0 &&
                      loanTypeOptions.find(
                        (ele) => ele.id == controlValue.loan_type_id
                      ).name
                    }}</b-col>
                    <b-col cols="8" v-else> 不限 </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <table class="report-table mt-1 mb-0">
          <thead>
            <tr>
              <th>還款限期</th>
              <th>貸款編號</th>
              <th>客戶名稱</th>
              <th>期數/總期數</th>
              <th>欠款</th>
              <th>過期日</th>
              <th>過期利息</th>
              <th>附加費</th>
              <th>總欠款</th>
            </tr>
          </thead>
        </table>
      </b-card-body>

      <table class="w-100">
        <thead>
          <tr>
            <td>
              <div class="header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b-card-body class="report_content">
                <table class="report-table">
                  <tbody>
                    <tr v-for="item of reportList" :key="item.id">
                      <th>{{ item.pay_date }}</th>
                      <th>
                        {{
                          item.client_loan && item.client_loan.contract_number
                        }}
                      </th>
                      <th>
                        {{ item.client_loan && item.client_loan.name_cn }}
                      </th>
                      <th>
                        {{ item.period }} /
                        {{ item.client_loan && item.client_loan.period }}
                      </th>

                      <th>
                        ${{
                          item.actual_remain_amount -
                          (item.defer_day * item.defer_fee +
                            item.postpone_day * item.postpone_fee +
                            item.additional_fee)
                        }}
                      </th>

                      <th>{{ cal_defer_date(item.pay_date) }}日</th>
                      <th>
                        ${{
                          Number(item.defer_day) * Number(item.defer_fee) +
                          Number(item.postpone_day) * Number(item.postpone_fee)
                        }}
                      </th>
                      <th>${{ item.additional_fee }}</th>
                      <th>
                        ${{
                          Number(item.actual_remain_amount) +
                          Number(item.interest)
                        }}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
      <b-card-body class="report_footer py-0">
        <table class="report-table">
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) =>
                      acc +
                      Number(
                        curr.actual_remain_amount -
                          (curr.defer_day * curr.defer_fee +
                            curr.postpone_day * curr.postpone_fee +
                            curr.additional_fee)
                      ),
                    0
                  )
                }}
              </th>
              <th></th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) =>
                      acc +
                      (Number(curr.defer_day) * Number(curr.defer_fee) +
                        Number(curr.postpone_day) * Number(curr.postpone_fee)),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + Number(curr.additional_fee),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) =>
                      acc +
                      (Number(curr.actual_remain_amount) +
                        Number(curr.interest)),
                    0
                  )
                }}
              </th>
            </tr>
          </tfoot>
        </table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BCardText } from "bootstrap-vue";

import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      month: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  methods: {
    cal_defer_date(key_date) {
      const data1 = new Date();
      data1.setHours(0, 0, 0, 0);
      const timeDiff = Math.abs(data1.getTime() - new Date(key_date).getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays;
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
  },
  props: {
    reportList: {
      type: Array,
      required: true,
    },
    loanTypeOptions: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    // this.$nextTick(() => {
    //     setTimeout(() => {
    //         this.$emit("domRendered");
    //     }, 1000);
    // });
  },
  computed: {
    ...mapFields(["controlValue"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
.report-table {
  font-size: 12px;
  th {
    width: 80px;
  }
}

.report_content {
    margin-top: 115px;
  .report-table{
  }
}

</style>